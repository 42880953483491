body{
  height: 100%;
}

#rootbox{
  height: 100%;
}

.margin-left-20.MuiButton-root.file-upload-feedback-upload-new-btn{
  margin-left: 20px;
}

.loader{
  position: fixed;
  top: 0;
  bottom: 0%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10002;
}

.loader-box{
  width: 750px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  top: -70px;
  left: -70px;
}

.bar {
  position: relative;
  height: 2px;
  width: 500px;
  margin: 0 auto;
  background: #3c7ac6;
  margin-top: 150px;
}

.circle {
  position: absolute;
  top: -30px;
  margin-left: -30px;
  height: 60px;
  width: 60px;
  left: 0;
  background: #3c7ac6;
  border-radius: 30%;
  animation: move 5s infinite;
}

p.loader-text {
  position: absolute;
  top: -20px;
  right: -100px;
  text-transform: uppercase;
  color: #fff;
  font-family: helvetica, sans-serif;
  font-weight: bold;
}

@keyframes move {
  0% {left: 0;}
  50% {left: 100%; -webkit-transform: rotate(450deg); width: 150px; height: 150px;}
  75% {left: 100%; -webkit-transform: rotate(450deg); width: 150px; height: 150px;}
  100% {right: 100%;}
} 

.upload-file-wrapper{
  display: flex;
  /* margin: 10px; */
  padding: 10px;
}

.file-upload-feedback-uploaded-name{
  color: #0458C2;
}

.file-upload-feedback-upload-new-btn.MuiButton-root{
  display: inline-block;
  margin-left: 10px;
}

.header-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.search-wrapper{
  display: flex;
  height: 100px;
  align-items: center;
}

.processed-body-wrapper{
  border-top: 2px solid rgba(196, 196, 196, 0.5);
  /* padding-top: 20px; */
}

.processed-column{
  padding-left: 30px;
  padding-right: 15px;
  padding-bottom: 20px;
}

.processed-text{
  margin-bottom: 0px;
  font-size: 14px;
}

.processed-item{
  border-radius: 5px;
  background: rgba(24,118,209, 0.2);
  margin-top: 15px;
  
}

.processed-text-text-wrapper{
  height: 700px;
  overflow-x: scroll;
  padding: 10px;
  background: #f3f4f7;
}

.processed-match{
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: rgba(61, 166, 73, 0.1);
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.processed-text-matches-wrapper{
  height: 400px;
  width: 100%;
  overflow-x: scroll;
  display: inline-block;
  border-bottom: 1px solid #ccc;
  background: #f3f4f7;
}

.process-match-quantity-input.MuiFormControl-root{
  width: 70px;
  height: 100%;
  margin-right: 15px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
}

.add-item-search-dialog .MuiPaper-root{
  width: 700px;
  min-height: 400px;
}

.add-item-search-input-wrapper{
  margin-top: 20px;
}

.add-item-buttons-wrapper.MuiDialogActions-root{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.add-item-list-item-wrapper{
  margin-top: 10px;
  border-radius: 5px;
  padding: 4px;
  background: rgba(196, 196, 196, 0.3);
  cursor: pointer;
}

.add-item-list-item-wrapper.selected{
  background: rgba(104, 113, 193, 0.3);
}

.process-search-approve-btn.MuiButtonBase-root{
  margin-left: 15px;
}

.select-view-btn-wrapper{
  position: absolute;
  right: 50px;
  top: 12px;
}

.select-view-btn-wrapper .select-view-btn-btn{
  margin-left: 10px;
}

.manual-search-box-wrapper{
  height: 70px;
  padding: 7px;
  padding-left: 25px;
  width: 60%;
  display: flex;
}

.manual-body-result-box{
  width: 100%;
  height: 650px;
  overflow-x: scroll;
}

.manual-body-order-box{
  width: 100%;
  height: 550px;
  overflow-x: scroll;
}

.manual-body-result-box{
  padding: 20px;
}

.manual-body-result-item-wrapper{
  display: flex;
}

.manual-body-result-header{
  padding-left: 40px;
}

.manual-body-result-item-wrapper .manual-body-result-item-add-icon{
  position: relative;
  top: 10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.manual-body-order-item-wrapper{
  display: flex;
}

.manual-body-order-item-remove-icon{
  cursor: pointer;
}

.manual-body-order-item-wrapper{
  display: flex;
}

.manual-body-roder-item-quantity-input.MuiFormControl-root{
  width: 150px;
  margin-right: 10px;
}

.manual-body-roder-item-quantity-input.MuiFormControl-root input{
  font-size: 14px;
}

.manual-body-order-item-wrapper{
  background: rgba(24,118,209, 0.1);
}

.process-item-select-match-header-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.processed-select-match{
  background: rgba(24,118,209, 0.1);
  font-size: 14px;
}

.upload-file-feedback-wrapper{
  max-width: 900px;
}

.upload-file-feedback-wrapper .file-upload-feedback-uploaded-name{
  overflow-wrap: break-word;
}

.ir-article-nubmer-inline{
  margin-right: 5px;
  background-color: #aec1da;
}

.recognized-item-text{
  padding: 3px;
  border-radius: 8px;
}

.recognized-item-text.highlight{
  background: rgba(124, 51, 240, 0.3);
}

.processed-column-items-item{
  font-size: 13px;
  border: 1px solid #3c7ac6;
  padding: 3px;
  width: 98%;
  border-radius: 3px;
  margin-bottom: 3px;
}

.process-column-item-actions{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
}

.process-column-item-actions span{
  margin-right: 20px;
}

.process-column-item-body .css-6qfsqn-MuiTableCell-root{
  font-size: 0.7rem;
  padding-left: 3px;
  padding-right: 3px;
}

.process-column-item-body .css-dv0mq-MuiTableCell-root, .process-column-item-body .css-dsuxgy-MuiTableCell-root{
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.8rem;
  text-align: center;
}

.process-column-item-initial{
  font-weight: 600;
}

.process-column-item-body-select:hover{
  color: green;
}

.process-column-item-body-search .manual-search-box-wrapper{
  width: 90%;
  font-size: 13px;
  height: auto;
}

.process-column-item-body-search .manual-search-box-wrapper .css-mixo3p-MuiInputBase-input-MuiOutlinedInput-input{
  font-size: 0.8rem;
}

.process-column-item-selected svg{
  color: green;
}

.MuiTableBody-root .process-column-item-body-select-selected{
  color: green;
  font-weight: 600;
}

.process-column-header-with-actions{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.process-column-header-with-actions h4{
  margin-right: 20px;
}

.process-btn-next.MuiButton-root{
  margin-left: 160px;
}